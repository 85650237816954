
import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiGetMapKey, apiSetMapKey } from '@/api/setting'
import materialSelect from '@/components/material-select/index.vue'

@Component({
    components: {
        materialSelect
    }
})
export default class ListsDetail extends Vue {
    /** S Data **/

    // 添加商城表单数据
    form: any = {
        tencent_map_key: '', // 客服名称
    };

    // 表单校验
    rules = {
        tencent_map_key: [{ required: true, message: '请输入腾讯地图密钥', trigger: 'blur' }]
    };

    /** E Data **/

    /** S Methods **/
    // 点击表单提交
    onSubmit(formName: string) {
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement
        refs.validate((valid: boolean): any => {
            if (!valid) return
            this.handleEdit()
        })
    }

    async handleEdit() {
        await apiSetMapKey({ ...this.form })
        this.getShopDetailFunc()
    }

    // 获取详情
    async getShopDetailFunc(): Promise<void> {
        const res: any = await apiGetMapKey()
        Object.keys(res).map((key) => {
            this.$set(this.form, key, res[key])
        })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getShopDetailFunc()
    }
    /** E Life Cycle **/
}
